import { subYears } from 'date-fns';
import * as Yup from 'yup';
import { COMMON_WORDS } from '../../constants/words';
import {
  hasCommonWords,
  hasConsecutiveSymbols,
  hasLoginPassSameString,
  hasRequiredSymbols,
  hasRestrictedSymbols,
  minDate,
  today,
} from '../../helpers/utils';

const wordsSeparatedWithOneSpaceOrOneApostropheRegExp = /^[a-zA-Z]+(?:[`'\s][a-zA-Z]+)*$/;
const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
const emailUnusualCharactersErrorMessage = 'Unusual characters (ã, ó etc.) are not supported';
const numbersUnusualCharactersErrorMessage =
  'Numbers and unusual characters (ã, ó,?,! etc.) are not supported';
const MAX_POSTAL_CODE_CHARACTERS = 10;
const maxOneSpaceNoLeadingNoTrailingSpaceMaxOneDash = /^(?=.{1,10}$)+^[a-zA-Z0-9]+([\s-]?)?[a-zA-Z0-9]+$/gm; // QWERTY, QW ERTY, QW-ERTY, 1234
export const TAX_IBI_TYPES = {
  SSN: 'SSN',
  VAT: 'VAT',
  NIN: 'NIN',
  ABN: 'ABN',
  TFN: 'TFN',
};

export const strings = {
  title: 'Checkout-Be Backoffice',
  welcome: 'JOIN OUR FAMILY',
  createAnAccount: 'Become a Publisher',
  putInYourInformation: 'putInYourInformation',
  registeredAlready: 'registeredAlready',
  signIn: 'signIn',
  referralIDText: 'refferalIDText',
  referralIDRefferalNameText: 'Referral ID or Referral Name',
  referralPopover:
    'Please enter the ID of the person who referred you. If you don’t have the ID, please ask the person who referred you for the ID.',
  referralFullName: 'Referral Name',
  yourFullNameText: 'Your Full Name',
  firstNameText: 'First Name',
  middleNameText: 'Middle Name',
  lastNameText: 'Last Name',
  countryText: 'Country of Residence',
  state: 'State',
  yourCountryText: 'Country of Residence',
  personalInformation: 'Personal Information\n',
  email: 'Email',
  dateOfBirthday: 'Date Of Birthday',
  phone: 'Phone',
  phoneNumber: 'Phone number',
  language: 'Language',
  city: 'City',
  address: 'Address',
  postalCode: 'Postal Code',
  ssn: 'SSN',
  ssnWorldWide: `${TAX_IBI_TYPES.VAT}, ${TAX_IBI_TYPES.NIN}, ${TAX_IBI_TYPES.ABN}, ${TAX_IBI_TYPES.TFN}`,
  company: 'Company',
  companyInfo: 'Company Information (Optional)',
  companyName: 'Company Name',
  dateOfIncorporationOfCompany: 'Date of Incorporation of Company',
  coApplicant: 'Co-Applicant',
  coApplicantOptional: 'Co-Applicant (Optional)',
  coapplicantFirstName: 'Co-Applicant First Name',
  coapplicantLastName: 'Co-Applicant Last Name',
  companyRegistrationNumber: 'Company Registration Number',
  number: 'Number',
  taxFilingNumber: 'Tax Filing Number',
  taxCode: 'Tax Code',
  SIRETNumber: 'SIRET Number',
  IntraCommunityVATNumber: 'Intra-community VAT number',
  createUsernamePassword: 'Create Username And Password',
  socialMedia: 'Social media links',
  socialMediaError: 'At least one social media link is required',
  yourUsername: 'Username',
  createUsername: 'Create Username',
  yourPassword: 'Your Password',
  createPassword: 'Create Password',
  haveReadUnderstood: 'I have read, understood and agreed to',
  confirm18:
    'By clicking on the Get Started and ticking the Checkbox, you confirm that you are 18 years old (and if a Company then authorised to enter into the agreement to become a Publisher), and have read, understood and explicitly agree with our',
  allowToStoreYourInfo:
    'By clicking on the Get Started and ticking the Checkbox, you consent and allow us to store all the above information and you confirm that the information provided by you is accurate. Additionally, By clicking on the Get Started and ticking the Checkbox, you explicitly consent to provide your personal identity documents or company documents (if registering as a company) for us to fulfil our compliance requirements',
  getStarted: 'Get Started',
  privacyPolicy: 'Privacy Policy',
  gdprPolicy: 'GDPR Policy',
  termsOfUse: 'Terms Of Use',
  refundPolicy: 'Refund Policy',
  policiesAndProcedures: 'Policies and Procedures',
  ibi: 'Publisher Agreement',
  compensationPlan: 'Compensation Plan',
};

export const getValidationSchema = (values, isUser) => {
  const isFrance = values?.country === 'fr';
  const isUsa = values?.country.toLowerCase() === 'us';
  const isCanada = values?.country === 'ca';
  const isCompanyCheckbox = values?.companyCheckbox;

  const validateCompanyRegistrationNumber = () => {
    if (!isFrance) {
      return Yup.string()
        .test('valid', 'Letters and numbers only', (val = '') => {
          const isEngLetterAndNumbers = RegExp(/[^a-zA-Z0-9]+/g).test(val.replace(/\u200C/g, ''));
          return !isEngLetterAndNumbers;
        })
        .required('Company Registration Number is required');
    }

    // eslint-disable-next-line no-use-before-define
    return lettersAndNumbersOnly();
  };

  const validateTaxFilingNumber = () => {
    if (!isFrance && !isUsa) {
      return Yup.string()
        .test('valid', 'Letters and numbers only', (val = '') => {
          const isEngLetterAndNumbers = RegExp(/[^a-zA-Z0-9]+/g).test(val.replace(/\u200C/g, ''));
          return !isEngLetterAndNumbers;
        })
        .required('Tax Filling Number is required');
    }

    // eslint-disable-next-line no-use-before-define
    return lettersAndNumbersOnly();
  };

  const lettersAndNumbersOnlyRequired = () =>
    Yup.string()
      .test('valid', 'Letters and numbers only', (val = '') => {
        const isEngLetterAndNumbers = RegExp(/[^a-zA-Z0-9]+/g).test(val.replace(/\u200C/g, ''));
        // const numbersOnly = RegExp(/[^0-9]+/g).test(val.replace(/\u200C/g, ''));
        return !isEngLetterAndNumbers;
      })
      .required('Field is required');

  const lettersAndNumbersOnly = () =>
    Yup.string().test('valid', 'Letters and numbers only', (val = '') => {
      const isEngLetterAndNumbers = RegExp(/[^a-zA-Z0-9]+/g).test(val.replace(/\u200C/g, ''));
      // const numbersOnly = RegExp(/[^0-9]+/g).test(val.replace(/\u200C/g, ''));
      return !isEngLetterAndNumbers;
    });

  return Yup.object().shape({
    firstName: Yup.string()
      .required('First name is required')
      .min(2, 'First Name should contain at least two letters')
      .matches(
        wordsSeparatedWithOneSpaceOrOneApostropheRegExp,
        numbersUnusualCharactersErrorMessage
      ),

    middleName: Yup.string().matches(
      wordsSeparatedWithOneSpaceOrOneApostropheRegExp,
      numbersUnusualCharactersErrorMessage
    ),

    coapplicantFirstName: Yup.string().matches(
      wordsSeparatedWithOneSpaceOrOneApostropheRegExp,
      numbersUnusualCharactersErrorMessage
    ),

    coapplicantLastName: Yup.string().matches(
      wordsSeparatedWithOneSpaceOrOneApostropheRegExp,
      numbersUnusualCharactersErrorMessage
    ),

    lastName: Yup.string()
      .required('Last name is required')
      .min(2, 'Last Name should contain at least two letters')
      .matches(
        wordsSeparatedWithOneSpaceOrOneApostropheRegExp,
        numbersUnusualCharactersErrorMessage
      ),

    country: Yup.string().required('Country is required'),
    email: Yup.string()
      .matches(emailRegExp, emailUnusualCharactersErrorMessage)
      .email()
      .required('Email is required'),

    birthDate: Yup.date('Date of Birth is required')
      .nullable()
      .typeError("Field shouldn't be empty")
      .required('Date of Birth is required')
      .min(minDate, 'Date of birth cannot be before 1900')
      .max(subYears(today, 18), 'You should be at least 18 years old to continue'),

    phone: Yup.string()
      .matches(/^[0-9]{5,13}$/, 'Must be a valid number')
      .required('Phone is required'),

    language: Yup.string().required('Language is required'),

    city: Yup.string()
      .matches(
        /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
        'Should contain only letters'
      )
      .max(50, 'City should be not more than 50 characters long')
      .required('City is required'),

    zipCode: Yup.string()
      .matches(maxOneSpaceNoLeadingNoTrailingSpaceMaxOneDash, 'Postal Code is not valid')
      .max(MAX_POSTAL_CODE_CHARACTERS, 'Postal Code must be 10 letters/numbers or shorter')
      .required('Postal Code is required'),

    address: Yup.string().required('Address is required'),

    ssn: Yup.string()
      .test('valid', 'Must be a numbers or/and letters', (val = '') => {
        const isEngLetterAndNumbers = RegExp(/[^a-zA-Z0-9]+/g).test(val.replace(/\u200C/g, ''));
        return !isEngLetterAndNumbers;
      })
      .required(
        isFrance || isUsa || isCanada
          ? 'SSN is required'
          : `${TAX_IBI_TYPES.VAT}, ${TAX_IBI_TYPES.NIN}, ${TAX_IBI_TYPES.ABN}, ${TAX_IBI_TYPES.TFN} is required`
      ),

    state: isUsa ? Yup.string().required('State is required') : Yup.string(),

    taxFilingNumber: isCompanyCheckbox
      ? validateTaxFilingNumber()
      : Yup.string().test('valid', 'Must be a valid number', (val = '') => {
          const numbersOnly = RegExp(/[^0-9]+/g).test(val.replace(/\u200C/g, ''));
          return !numbersOnly;
        }),

    companyRegistrationNumber: isCompanyCheckbox
      ? validateCompanyRegistrationNumber()
      : Yup.string().test('valid', 'Must be a valid number', (val = '') => {
          const numbersOnly = RegExp(/[^0-9]+/g).test(val.replace(/\u200C/g, ''));
          return !numbersOnly;
        }),

    SIRETNumber:
      isFrance && isCompanyCheckbox ? lettersAndNumbersOnlyRequired() : Yup.mixed().notRequired(),

    IntraCommunityVATNumber:
      isFrance && isCompanyCheckbox ? lettersAndNumbersOnlyRequired() : Yup.mixed().notRequired(),

    loginName: Yup.string()
      .required('Username is required')
      .test('valid', 'Username should have English letters and numbers only', (val = '') => {
        const isEngLetterAndNumbers = RegExp(/[^a-zA-Z0-9]+/g).test(val.replace(/\u200C/g, ''));
        const numbersOnly = RegExp(/[^0-9]+/g).test(val.replace(/\u200C/g, ''));
        return !isEngLetterAndNumbers && numbersOnly;
      })
      .max(50, 'Username must be not more than 50 characters'),

    loginPassword: Yup.lazy((value) => {
      if (isUser) {
        return Yup.mixed().notRequired();
      }

      if (value) {
        return Yup.mixed()
          .test(
            'has-consecutive',
            'should not have 3 consecutive letters or numbers',
            hasConsecutiveSymbols
          )
          .test(
            'has-min-required-characters',
            'should have 1 letter / 1 number / 1 uppercase / 1 special characters',
            hasRequiredSymbols
          )
          .test('is-long-enough', 'minimum 10 characters long', (password) => password.length >= 10)
          .test(
            'common-words',
            `should not use common-words: ${COMMON_WORDS.words.join()}`,
            (password) => hasCommonWords(password, COMMON_WORDS.pattern)
          )
          .test('test-same', 'login and password could not be the same', hasLoginPassSameString)
          .test(
            'unsupported symbols',
            'please use only a-z letters, numbers and special symbols "!$%^&*()_+|~=`{}:;<>?,.@#"',
            hasRestrictedSymbols
          )
          .required('this field is required');
      }

      return Yup.mixed().required('Password is required');
    }),

    privacy: Yup.bool().oneOf([true], 'Field must be checked'),
    terms: Yup.bool().oneOf([true], 'Field must be checked'),

    socialNetworkLinks: Yup.string().min(
      3,
      'Social media link should contain at least two letters'
    ),
    // .required('At least 1 social media link is required'),
  });
};

export const SOCIAL_NETWORK_NAME = {
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  YOUTUBE: 'youtube',
  INSTAGRAM: 'instagram',
  TIKTOK: 'tiktok',
};

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const convertSociaLNetworkName = (socialNetwork) => {
  if (socialNetwork === SOCIAL_NETWORK_NAME.TWITTER) {
    return 'X';
  }

  if (socialNetwork === SOCIAL_NETWORK_NAME.YOUTUBE) {
    return 'YouTube';
  }

  return capitalizeFirstLetter(socialNetwork);
};
