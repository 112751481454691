import clsx from 'clsx';

import PropTypes from 'prop-types';
import React from 'react';

import styles from './inputBlockRegistation.module.scss';

const InputBlockRegistation = React.forwardRef((props, ref) => {
  const { title, children, bordered, errorMessage, isOptional } = props;

  return (
    <div ref={ref} className={clsx(styles.wrapper, bordered ? null : styles.withoutBorder)}>
      {title ? (
        <div className={clsx(styles.title, errorMessage ? styles.isErrorTitle : null)}>
          <span>{title}</span>
          {isOptional && <i style={{ fontSize: '14px' }}> (Optional)</i>}
        </div>
      ) : null}

      {children}

      {errorMessage && errorMessage.length && (
        <p className={styles.errorMessage}> {errorMessage}</p>
      )}
    </div>
  );
});

InputBlockRegistation.defaultProps = {
  title: null,
  bordered: true,
  isOptional: false,
  errorMessage: '',
};

InputBlockRegistation.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  bordered: PropTypes.bool,
  isOptional: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default InputBlockRegistation;
