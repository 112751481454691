import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select/async';
import states from '../../constants/states';
import customStyles from '../../constants/selectStyles';
import styles from './state-select.module.scss';

const StateSelect = ({
  id,
  onChange,
  value,
  className,
  invertDark,
  placeholder,
  label,
  filterKeys,
  errorMessage,
  ...rest
}) => {
  const options = states.map(({ name, code }) => ({ value: code, label: name }));

  const filterStates = (stateValue) =>
    new Promise((resolve) => {
      resolve(
        states
          .filter((state) => state.name.toLowerCase().includes(stateValue.toLowerCase()))
          .map(({ name, code }) => ({ value: code, label: name }))
      );
    });

  const defaultValue = options.find(
    (item) => item.value === value || (value && item.value === value.toLowerCase())
  );

  return (
    <div className={clsx(styles.wrapper, className)}>
      {label && (
        <label className="basicLabel" htmlFor={id}>
          {label}
          {rest.required && (
            <span aria-hidden className="asterisk">
              *
            </span>
          )}
        </label>
      )}

      <Select
        id={id}
        defaultOptions={options}
        styles={customStyles}
        placeholder={placeholder}
        loadOptions={(state) => filterStates(state)}
        value={defaultValue || ''}
        onChange={onChange}
        isSearchable
        cacheOptions
        maxMenuHeight={300}
        errorMessage={errorMessage}
      />
      {errorMessage ? <div className={styles.errorMessage}>{errorMessage}</div> : null}
    </div>
  );
};

StateSelect.defaultProps = {
  options: [],
  value: '',
  errorMessage: '',
  label: '',
  placeholder: '',
  className: null,
  invertDark: null,
  filterKeys: [],
};

StateSelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
    }).isRequired
  ),
  className: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  invertDark: PropTypes.bool,
  filterKeys: PropTypes.arrayOf(PropTypes.string),
};

export default StateSelect;
